body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: var(--din-light);
  box-sizing: border-box;
  color: var(--sort);
  text-underline-offset: 0.05em;

  /* fonts */
  --din-light: DINOT-Light, 'Calibre Light', sans-serif;
  --din-light-italic: DINOT-Light-Italic, 'Calibre Light', sans-serif;
  --din-regular: DINOT, 'Calibre Light', sans-serif;
  --din-medium: DINOT-Medium, 'Calibre Light', sans-serif;
  --newzald-book: Newzald-Book, Georgia, serif;

  /* font-sizes used */
  --10px-rem: 0.625rem;
  --12px-rem: 0.75rem;
  --14px-rem: 0.875rem;
  --16px-rem: 1rem;
  --18px-rem: 1.125rem;
  --20px-rem: 1.25rem;
  --24px-rem: 1.5rem;
  --28px-rem: 1.75rem;
  --32px-rem: 2rem;
  --36px-rem: 2.25rem;
  --40px-rem: 2.5rem;
  --46px-rem: 2.875rem;
  --48px-rem: 3rem;
  --64px-rem: 4rem;
  --96px-rem: 6rem;

  /* spacing unit tokens */
  --neg-2: -16px;
  --1px: 1px;
  --2px: 2px;
  --05: 4px;
  --075: 6px;
  --1: 8px;
  --1-05: 12px;
  --2: 16px;
  --3: 24px;
  --4: 32px;
  --5: 40px;
  --6: 48px;
  --7: 56px;
  --8: 64px;
  --9: 72px;
  --10: 80px;
  --11: 88px;
  --15: 120px;
  --16: 128px;
  --17: 136px;
  --18: 144px;
  --19: 152px;
  --20: 160px;
  --21: 168px;
  --22: 176px;
  --23: 184px;
  --25: 200px;
  --30: 240px;
  --33: 264px;
  --38: 304px;
  --40: 320px;
  --44: 352px;
  --50: 400px;
  --102: 816px;
  --125: 1000px;
}

h1 {
  font-size: var(--40px-rem);
}

h1,
h2,
h3,
h4 {
  font-family: var(--newzald-book);
  font-weight: 100;
}

h3 {
  font-size: var(--24px-rem);
}

* {
  box-sizing: border-box;
  line-height: 1.5;
}

/* Prevent the top of Å-s in placeholders to be cut off */
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  overflow: visible;
}
::-moz-placeholder {
  /* Firefox 19+ */
  overflow: visible;
}
:-ms-input-placeholder {
  /* IE 10+ */
  overflow: visible;
}
:-moz-placeholder {
  /* Firefox 18- */
  overflow: visible;
}

main {
  padding-bottom: 60px;
}